import React, { useEffect, useState } from "react";
import { background, border, FormControl, FormLabel} from "@chakra-ui/react"; 
import utils from "../../../utils/commonFunctions";
import Select from 'react-select'; 
const styles = {
    control: (baseStyles,state) => ({
        ...baseStyles,
        borderRadius: '15px',
        padding:'4px 0',
        borderColor: state.isFocused ? 'grey' : 'platinum-229', // Use state.isFocused for active/focus state
        '&:hover': {
            borderColor: 'lightgrey', // Correctly set hover border color
        },
        
    }),
    
}

export const SelectInput = ({value,callback,label,isRequired=true,options,placeholder,name,id}) => {
    return (
        <FormControl isRequired={isRequired}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <Select 
                id={id}
                name={name}
                placeholder={placeholder}
                options={options}
                value={value}
                onChange={(e) => callback(e)}
            />
        </FormControl>
    );
};

export const AreaInput = ({value,callback,cityName,isRequired=true}) => {
    const [areas, setAreas] = useState([]);
    const [areasOptions, setAreasOptions] = useState([]);
    const [option, setOption] = useState(null);
    const getAreas = async () => {
        try {
           
            let fetchedAreas = await utils.fetchAreas({cityName: cityName});
           //let fetchedAreas = await utils.fetchAreas({});
            if (fetchedAreas && fetchedAreas.length > 0) {
                setAreasOptions(fetchedAreas.map(area => ({
                    label: area.name,
                    value: area.name,
                    name: 'area'
                })));  
               
                setAreas(fetchedAreas);
               
            } else {
                setAreas([]);
            }
        } catch (e) {
            console.error('Failed to fetch areas:', e);
            setAreas([]);
        }
    };

    useEffect(() => {
        if (cityName) {
            getAreas();
            setOption(null)
        }
    }, [cityName]);
    return (
        <FormControl isRequired={isRequired}>
            <FormLabel htmlFor="area">Area / Ward</FormLabel>
            <Select 
                id="area"
                name="ward"
                placeholder="Select area"
                value={option}
                options={areasOptions}
                onChange={
                    (e) => {
                        callback({ target: e });
                        setOption(value?.target?.value);
                    }
                }

                styles={styles}
            />
               
        </FormControl>
    );
};

export const CityInput = ({value,callback,isRequired=true,placeholder='Select city',label='City / Town / Village'}) => {
    const [cityOptions, setCityOptions] = useState([]);
    useEffect(() => {
       
    }, [cityOptions]);
    const getCities = async () => {
        try {
            let fetchedCities = await utils.fetchCities({ pageSize: 90000 });
            if (fetchedCities && fetchedCities.length > 0) {
                setCityOptions(fetchedCities.map(city => ({
                    label: city.name,
                    value: city.name,
                    name: 'city'
                })));  
            } 
        } catch (e) {
            console.error('Failed to fetch cities:', e);
        }
    };
    useEffect(() => {
        getCities();
    }, []);
   
    return (
        <FormControl isRequired={isRequired}>
            <FormLabel htmlFor="city">{label}</FormLabel>
            <Select 
                id="city"
                name="city"
                placeholder={placeholder}
                value={value?.target?.value} 
                onChange={(e) => callback({target:{...e}})}
                options={cityOptions}
                isSearchable 
                styles={styles}
            />
        </FormControl>
    );
};
